<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="loaded"
    :extra-actions="extraActions"
  >
    <b-card v-if="loaded">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFields"
            allow-autofocus
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'

import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'

import storeModule from '../announcementStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    EntitySingle,
    EntityEditSection,
  },
  data() {
    return {
      entity: {
        publicAnnouncement: true,
        enabled: true,
      },
      loaded: false,
      categoryOptions: [],
      sellerOptions: [],
      supplierOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'announcements',
        endpoint: 'announcements',
        route: 'announcements',
        title: {
          single: this.$i18n.t('Announcement'),
          plural: this.$i18n.t('Announcements'),
        },
      }
    },

    infoFields() {
      return [
        {
          id: 'img',
          type: 'cropupload',
          label: `${this.$t('Logo')} (1600x900)`,
          colSize: 12,
          width: 1600,
          height: 900,
          autoupload: true,
          required: false,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
          colSize: 12,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$i18n.t('Description'),
          required: true,
          colSize: 12,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
          colSize: 2,
        },
        {
          id: 'sendNotification',
          type: 'checkbox',
          label: this.$t('send notification'),
          colSize: 2,
        },
        {
          id: 'categories',
          object: true,
          type: 'selectmultiple',
          label: this.$t('Categories'),
          options: this.categoryOptions,
          required: false,
          colSize: 8,
        },
        {
          id: 'privateAnnouncement',
          type: 'checkbox',
          label: this.$t('is private'),
          colSize: 2,
        },
        {
          id: 'sellers',
          type: 'selectmultiple',
          label: this.$t('Sellers'),
          options: this.sellerOptions,
          colSize: 5,
          dependency: 'privateAnnouncement',
        },
        {
          id: 'suppliers',
          type: 'selectmultiple',
          label: this.$t('Suppliers'),
          options: this.supplierOptions,
          colSize: 5,
          dependency: 'privateAnnouncement',
        },
      ]
    },
    extraActions() {
      return [
        {
          icon: 'EyeIcon',
          title: 'View in website',
          href: this.entity.websiteSlug,
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'website/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to website',
          renderCondition: {
            key: 'published',
            value: false,
          },
        },
        {
          // callback: 'updatewebsite',
          endpoint: 'website/publish',
          icon: 'UploadCloudIcon',
          title: 'Update website',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'website/unpublish',
          icon: 'DownloadCloudIcon',
          title: 'Remove from website',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchPostCategories').then(response => {
      this.categoryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSellers').then(response => {
      this.sellerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSuppliers').then(response => {
      this.supplierOptions = response.data
    })
  },
}
</script>
